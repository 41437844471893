
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@include mat.core();
@import '~material-design-icons/iconfont/material-icons.css';

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

//@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@font-face {
  font-family: 'Gellix';
  src: url('./assets/fonts/Gellix/webfont/gellix-regular-webfont.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gellix';
  src: url('./assets/fonts/Gellix/webfont/gellix-medium-webfont.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gellix';
  src: url('./assets/fonts/Gellix/webfont/gellix-bold-webfont.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

$custom-typography: mat.define-typography-config(
  $font-family: 'Gellix'
);

//@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$incar-primary: mat.define-palette(mat.$green-palette, A700);
$incar-accent: mat.define-palette(mat.$grey-palette);

// The warn palette is optional (defaults to red).
$incar-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$incar-theme: mat.define-light-theme((
  color: (
    primary: $incar-primary,
    accent: $incar-accent,
    warn: $incar-warn,
  ),
  typography: $custom-typography,
));

//@include mat.all-component-themes($incar-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($incar-theme);

$incar-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $incar-primary,
      accent: $incar-accent,
      warn: $incar-warn,
    ),
    typography: $custom-typography,
  )
);

@include mat.all-component-themes($incar-theme);

.style-dark {
  @include mat.all-component-colors($incar-dark-theme);
}

.style-dark app-login {
  @include mat.card-color($incar-theme);
  @include mat.form-field-color($incar-theme);
}

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  //@include mat.all-component-themes($incar-theme);
  margin: 0; 
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

/*.no-horizontal-scroll {
  // overflow-x: hidden !important;
}*/

.no-horizontal-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

mat-fab {
  width: 60px;
  height: 60px;
}

iframe.intercom-launcher-frame, div.intercom-lightweight-app-launcher.intercom-launcher {
  bottom: 8px !important;
  right: 8px !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

mat-bottom-sheet-container {
  padding: 0 !important;
  width: 100% !important;
  max-width: 400px !important;
  &.mat-bottom-sheet-container-large {
    max-width: 800px !important;
  }
  &.mat-bottom-sheet-container-medium {
    max-width: 600px !important;
  }
  table {

    &.expanded-table {
      td:first-child {
        font-weight: bold;
        padding-right: 10px;
      }
    }

    width: 100%;

    tr.example-detail-row {
      height: 0;
    }
    
    /*tr.example-element-row:not(.example-expanded-row):hover {
      background: whitesmoke;
    }*/
    
    /*tr.example-element-row:not(.example-expanded-row):active {
      background: #efefef;
    }*/

    .example-element-row td {
      border-bottom-width: 0;
    }
    
    .example-element-detail {
      overflow: hidden;
      display: flex;
    }
    
    .example-element-diagram {
      min-width: 80px;
      border: 2px solid black;
      padding: 8px;
      font-weight: lighter;
      margin: 8px 0;
      height: 104px;
    }
    
    .example-element-symbol {
      font-weight: bold;
      font-size: 40px;
      line-height: normal;
    }
    
    .example-element-description {
      padding: 16px;
    }
    
    .example-element-description-attribution {
      opacity: 0.5;
    }

    td.mat-cell:first-of-type {
      padding-left: 6px;
    }
    td.mat-cell:last-of-type {
      padding-right: 6px;
    }
  }
}

table.chargers-table {
  // max-width: 512px;
  table-layout: fixed;
  td.ellipsis {
    div {
      overflow: hidden;
      width: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  td.mat-cell:nth-child(2) {
    width: 96px;
  }
  td.mat-cell:last-of-type {
    width: 48px;
  }
}

table.drivers-table {
  // max-width: 512px;
  table-layout: fixed;
  .status-BUSY {
    background-color: #f4cccc
  }
  .status-ENROUTE {
    background-color: #fce5cd
  }
  /*td.mat-cell:first-of-type {
    width: 40px;
  }*/
  /*td.mat-cell:nth-child(2) {
    width: 45px;
    div {
      margin-right: 8px;
    }
  }*/
  
  /*td.mat-cell:last-of-type {
    width: 110px;
  }*/
  td.ellipsis {
    div {
      overflow: hidden;
      width: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

table.bookings-table {
  // max-width: 512px;
  table-layout: fixed;
  .status-IN_PROGRESS {
    background-color: #fce5cd
  }
  .status-ACCEPTED {
    background-color: #c9d9f8
  }
  .status-COMPLETED {
    background-color: #d9ead3;
  }
  .status-CANCELLED {
    background-color: #f4cccc;
  }
  .status-TRIP_ON_TRIP {
    background-color: #B1F2EC;
  }
  td.mat-cell:first-of-type {
    width: 40px;
  }

  .no-nearby-drivers {
    background-color: #f4cccc;
  }

  /*td.mat-cell:nth-child(2) {
    width: 45px;
    div {
      margin-right: 8px;
    }
  }*/
  
  td.mat-cell:last-of-type {
    width: 130px;
  }
  td.ellipsis {
    div {
      overflow: hidden;
      width: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/*.mat-select-value, .mat-select-arrow {
  color: white !important;
}*/

.style-light {
  .drawer {
    .mat-slide-toggle-content, .mat-select-value {
      color: white;
    }

    .mat-select-arrow {
      color: #ffffffb3;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: #ffffffb3;
    }
  }
}

.style-dark {

  a {
    color: white;
  }

  .status-BUSY {
    background-color: #D32F2F !important;
  }
  .status-ENROUTE {
    background-color: #8B8000 !important;
  }
  .status-FREE {
    background-color: #00796B !important;
  }

  .status-IN_PROGRESS {
    background-color: #00796B !important;
  }
  .status-ACCEPTED {
    background-color: #0277BD !important;
  }
  .status-COMPLETED {
    background-color: #2E7D32 !important;
  }
  .status-CANCELLED {
    background-color: #D32F2F !important;
  }
  .status-TRIP_ON_TRIP {
    background-color: #AB47BC !important;
  }

  .no-nearby-drivers {
    background-color: #D32F2F !important;
  }

  .mapboxgl-popup-content {
    color: rgba(0,0,0,.87);
  }

  .logo {
    background-image: url('./assets/logos/light.png') !important;
  }

}

.resize-sheet {
  height: 10px;
  cursor: ns-resize;
}

.label-marker {
  position: relative;
  display: inline-block;
}

.label-marker .tooltiptext {
  white-space: nowrap;
  background-color: #1ee664;
  color: #000;
  text-align: center;
  padding: .15rem .3rem;
  border-radius: .3rem;
  font-size: .7rem;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);

  &.eta {
    background-color: #1e88e5;
  }

}

/* Tooltip arrow */
.label-marker .arrow.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1ee664 transparent transparent transparent;
}

.label-marker .arrow.tooltiptext.eta::after {
  border-color: #1e88e5 transparent transparent transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

/*body.style-dark {
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px rgb(66,66,66) inset !important;
    }
}*/

.tab-table-container {
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
}

// loading overlay

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 25, 26, 0.5);
  cursor: pointer;

  .loader {
      width: 30px;
      height: 30px;
      border-top: 3px solid #ffffff;
      border-right: 3px solid transparent;
      border-radius: 50%;
      animation: rotation 1s linear infinite;
      top: 50%;
      left: 50%;
      position: absolute;
      margin: -25px 0 0 -25px;
  }

  @keyframes rotation {
      from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }
  }
}

.md-drppicker .calendar {
  max-width: none !important;
  margin: 0 !important;
}

input[ngxdaterangepickermd]{
  background-color: transparent !important;
  color: currentColor !important;
  border: none !important;
  padding: 0 !important;
  width: 100% !important;
  &:focus-visible {
    outline: none !important;
  }
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}